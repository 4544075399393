<template>
  <div v-if="pageData" class="page container">
    <div class="nav-link">
      <router-link to="/">
        <img src="@/assets/images/arrows.svg">
      </router-link>
      <div class="title-2">{{ pageData.title }}</div>
    </div>

    <div class="content">
      <div class="form">
        <MPZForm />
      </div>
      <div class="services-item__color-block services-strapi-content" v-html="pageData.content" />
    </div>

    <div v-if="pageData.subservices.length" class="services-subitem-list">
      <div
        class="item"
        v-for="subservice in pageData.subservices"
        :key="subservice.id"
      >

        <div
          class="subservice-preview"
          v-if="subservice.preview.type === 'video'"
        >
          <video-background
            :src="subservice.preview.url"
            style="height: 100%; width: 100%"
          />
        </div>

        <div v-if="subservice.preview.type === 'image'" class="subservice-preview" :style="{backgroundImage: 'url(' + `${subservice.preview.url}` +')'}"/>

        <div class="title title-4">{{ subservice.title }}</div>
        <div class="description">{{ subservice.description }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import MPZForm from '@/components/MPZForm.vue'
import store from '@/store'
import VideoBackground from 'vue-responsive-video-background-player'

const route = useRoute()

const pageData = computed(()=> store.state.strapiData.services.find(item => item.link === route.params.id) || null)
</script>

<style scoped lang="scss">
a {
  text-decoration: underline;
  cursor: pointer;
}

.nav-link {
  margin-bottom: 60px;
  margin-top: 60px;
  display: flex;
  align-items: center;

  img {
    margin-right: 20px;
  }

  @media (max-width: 700px) {
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

.content {
  display: flex;
  gap: 0 10px;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  .form {
    width: 33.333%;

    @media (max-width: 1100px) {
      width: 50%;
      margin-bottom: 10px;
    }

    @media (max-width: 700px) {
      width: 100%;
    }
  }

  .services-docs {
    width: 66.666%;

    @media (max-width: 1100px) {
      width: 50%;
    }

    @media (max-width: 700px) {
      width: 100%;
    }
  }
}

.subservice-preview{
  width: 100%;
  height: 200px;
  background: #000;
  margin-bottom: 20px;
  border-radius: 12px;
  overflow: hidden;
  background: no-repeat 50%;
  background-size: cover;
}

.services-subitem-list{
  margin-top: 30px;
  border-radius: 30px;
  border: 1px solid #EAEAEA;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px 10px;
  .item{
    .title{
      border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      padding-bottom: 16px;
      margin-bottom: 20px;
    }
    width: 32.7%;
    @media (max-width: 1100px) {
      width: calc(50% - 5px);
    }
    @media (max-width: 700px) {
      width: 100%;
    }
  }
}
</style>